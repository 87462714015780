






































import { get } from "lodash-es";
import {
  computed,
  defineComponent,
  ref,
  Ref,
  watchEffect,
} from "@vue/composition-api";

export default defineComponent({
  name: "NavigationTree",
  props: {
    nodes: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const level: Ref<number> = ref(0);
    const path: Ref<string[]> = ref([]);

    const currentTitle = ref("");
    const currentNodes = ref(props.nodes);

    function goTo(index: number) {
      path.value = [...path.value, index.toString(), "children"];
      level.value++;
      emit("updateMeta", false);
    }

    function goBack() {
      path.value = path.value.slice(0, -2);
      level.value--;

      if (path.value.length < 1) {
        emit("updateMeta", true);
      }
    }

    const isMaxLevel = computed(() => level.value >= 2);

    watchEffect(() => {
      currentNodes.value = get(props.nodes, path.value, props.nodes);
      currentTitle.value = get(props.nodes, [
        ...path.value.slice(0, -1),
        "title",
      ]);
    });

    return {
      path,
      currentTitle,
      currentNodes,
      goTo,
      goBack,
      isMaxLevel,
    };
  },
});
