






















































import { defineComponent, ref, watch } from "@vue/composition-api";

import NavigationTree from "./NavigationTree.vue";

export default defineComponent({
  name: "Navigation",
  components: {
    NavigationTree,
  },
  props: {
    type: {
      type: String,
      default: "EVO",
    },
    open: {
      type: Boolean,
      default: false,
    },
    main: {
      type: Array,
      default: () => [],
      required: true,
    },
    meta: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  setup(props, { emit }) {
    const showMeta = ref(true);
    const isMenuOpen = ref(props.open);

    watch(
      () => props.open,
      () => {
        isMenuOpen.value = props.open;
      }
    );

    function closeMenu() {
      isMenuOpen.value = false;
      emit("updateMenuState", isMenuOpen.value);

      document.body.classList.remove("overflow-hidden");
    }

    function updateMeta(value: boolean) {
      showMeta.value = value;
    }

    return {
      showMeta,
      isMenuOpen,
      closeMenu,
      updateMeta,
    };
  },
});
