import Vue from "@/helper/initVue";

import MobileHeaderComponent from "@/components/header/MobileHeaderComponent.vue";

const rootEl = document.querySelector("#mobile-header");

const type = rootEl?.getAttribute("data-type");
const logoName = rootEl?.getAttribute("data-logo");
const pageNodes = rootEl?.getAttribute("data-page");
const loginNodes = rootEl?.getAttribute("data-login");
const mainNodes = rootEl?.getAttribute("data-main");
const metaNodes = rootEl?.getAttribute("data-meta");

new Vue({
  render: (h) =>
    h(MobileHeaderComponent, {
      props: {
        type: type,
        logo: logoName,
        page: pageNodes ? JSON.parse(pageNodes) : {},
        login: loginNodes ? JSON.parse(loginNodes) : [],
        main: mainNodes ? JSON.parse(mainNodes) : [],
        meta: metaNodes ? JSON.parse(metaNodes) : [],
      },
    }),
}).$mount("#mobile-header");
